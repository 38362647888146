import React, { useEffect, useState } from 'react';

import Logo from '../../../content/images/logo2.png';
import Image1 from '../../../content/images/header1.png';
import Background from '../../../content/images/bg-1.jpg';
import LogoMultipark from "../../../content/images/multipark.png";

export default function Header() {

    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='bg-[rgba(0,0,0,.5)] w-full min-h-screen px-[8vw] pt-[2rem] text-white bg-center bg-cover' style={{ backgroundImage: `url(${Background})` }}>
            <div className="absolute inset-0 w-full h-full bg-black bg-opacity-70"></div>
            <div className='relative flex items-center justify-between w-full gap-4'>
                <img src={Logo} alt="logo" className='w-[10rem] quatro:w-[12rem] seis:w-[17rem]' />
                <a
                    className={`relative font-bold hover:text-[var(--primary)] transition-all text-[1.2rem] group hidden cinco:flex flex-col`}
                    href="https://www.multipark.pt"
                >
                    <p className="text-[1.2rem]">
                        {lang === "pt" ? "Grupo" : "Group"}
                    </p>
                    <div className='bg-white py-1 group-hover:bg-[var(--primary)] transition-all'>
                        <img src={LogoMultipark} alt="logo" className="w-[10rem] group-hover:invert" />
                    </div>
                </a>
            </div>
            <div className='relative flex justify-between mt-[5rem]'>
                <div className='flex flex-col w-full gap-10'>
                    <h1 className='text-[2rem] seis:text-[2.5rem] font-bold'>{lang === "pt" ? "Estacione & Voe" : "Park & Fly"}</h1>
                    <h2 className='text-balance text-[1.5rem] quatro:text-[2rem] seis:text-[2.5rem] font-poppinsM'>
                        {lang === "pt" ? "Deixe o seu carro connosco e viaje em tranquilidade" : "Leave the car with us and travel in peace"}
                    </h2>
                    <div className='flex flex-col w-full gap-4 quatro:flex-row'>
                        <button className='bg-[var(--primary)] rounded-[10px] w-full py-4 font-bold hover:brightness-90 transition-all duration-300' onClick={() => window.location.href = "/contact"}>{lang === 'pt' ? 'CONTACTAR' : 'CONTACT'}</button>
                        <button onClick={() => window.open("https://multipark.pt/book?city=lisbon&parkBrand=skypark")} className='bg-[var(--primary)] rounded-[10px] w-full py-4 font-bold hover:brightness-90 transition-all duration-300'>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
                    </div>
                </div>
                <div className='items-end justify-center hidden w-full mil:flex'>
                    <img src={Image1} alt="header1" className='w-[25rem]' />
                </div>
            </div>
        </div>
    );
}

